import axios from "axios"

export class ServicoRetorno {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Retorno`
  }
  salvar(retorno) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", retorno, config).catch(error => {
      alert("Erro ao salvar o retorno " + error)
      return Promise.reject(error)
    })
  }
  devolverTodosItens(idNotaSaida) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + `/DevolverTodosItens/${idNotaSaida}`, config)
  }
  buscarDetalhesRetorno(id) {
    return axios({
      url: this.urlServico + `/GetVisualizacaoRetorno?id=${id}`,
      method: "GET"
    })
  }
  excluirNotaRetorno(id) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + `/ExcluirNotaRetorno/${id}`, config)
              .then(res => { return res})
  }
}
