<template>
  <div id="pageSaida">
    <v-container grid-list-xl fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <saida-table></saida-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SaidaTable from "@/components/notaSaida/tableSaida"
export default {
  components: {
    SaidaTable
  }
}
</script>
