<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-btn icon @click="pesquisar">
            <v-icon>search</v-icon>
          </v-btn>
          <v-text-field
            flat
            solo
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn icon @click="cadastro">
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="saidas"
          :rows-per-page-items="[10, 25, 50, 100, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.idRetorno }}</td>
            <td class="text-xs-left">{{ props.item.praca }}</td>
            <td class="text-xs-left">{{ props.item.vendedor }}</td>
            <td>{{ props.item.dataSaidaString }}</td>
            <td>{{ props.item.dataPrevistaRetornoString }}</td>
            <td class="text-xs-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" depressed icon dark v-bind="attrs" v-on="on">
                    <v-icon small>article</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-btn depressed icon dark color="primary" title="Visualizar" small @click="view(props.item.id)">
                    <v-icon small>remove_red_eye</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="props.item.idRetorno"
                    depressed
                    icon
                    dark
                    color="primary"
                    title="Visualizar retorno"
                    small
                    @click="viewRetorno(props.item.idRetorno)"
                  >
                    <v-icon small>shopping_bag</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    depressed
                    icon
                    dark
                    color="primary"
                    title="Adicionar retorno"
                    small
                    @click="retorno(props.item.id)"
                  >
                    <v-icon small>assignment_return</v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    icon
                    dark
                    color="primary"
                    title="Adicionar produtos"
                    small
                    @click="adicionarProdutos(props.item.id)"
                    v-if="props.item.idRetorno == null"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    icon
                    dark
                    color="primary"
                    title="Alterar datas"
                    small
                    v-if="props.item.idRetorno == null"
                    @click="alterarDatas(props.item.id)"
                  >
                    <v-icon small>calendar_today</v-icon>
                  </v-btn>
                  <v-btn
                    title="Devolver todos os itens"
                    v-if="props.item.idRetorno == null"
                    @click="devolverItens(props.item.id)"
                    depressed
                    icon
                    dark
                    color="primary"
                    small
                  >
                    <v-icon small>cached</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="props.item.idRetorno == null"
                    title="Unificar"
                    depressed
                    icon
                    dark
                    color="primary"
                    @click="unificar(props.item.id)"
                    small
                  >
                    <v-icon small>compare_arrows</v-icon>
                  </v-btn>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
        <div>
          <progress-circular></progress-circular>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoSaida } from "../../servicos/servicoSaida"
import { ServicoRetorno } from "../../servicos/servicoRetorno"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoSaida = new ServicoSaida()
const servicoRetorno = new ServicoRetorno()
export default {
  components: {
    ProgressCircular
  },
  data() {
    return {
      search: "",
      saidas: [],
      saidasSemfiltro: [],
      headers: [
        { text: "Código", align: "left", value: "id", sortable: false },
        { text: "Retorno", align: "left", value: "idRetorno", sortable: false },
        { text: "Praça", align: "left", value: "praca", sortable: false },
        { text: "Vendedor", align: "left", value: "vendedor", sortable: false },
        { text: "Data de saída", value: "dataSaidaString", sortable: false },
        { text: "Data retorno", value: "dataPrevistaRetornoString", sortable: false },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    this.buscarSaidas()
    if (this.$route && this.$route.params) {
      this.search = this.$route.params.id
    }
  },
  methods: {
    buscarSaidas() {
      servicoSaida.buscarSaidas().then(
        res => {
          if (res.status === 200) {
            this.saidas = res.data
            this.saidasSemfiltro = this.saidas
          }
        },
        // eslint-disable-next-line
        err => console.log(err)
      )
    },
    retorno(id) {
      this.$router.push({ name: "retorno", params: { id: id } })
    },
    view(id) {
      this.$router.push({ name: "view-saida", params: { id: id } })
    },
    viewRetorno(idRetorno) {
      this.$router.push({ name: "view-retorno", params: { id: idRetorno } })
    },
    cadastro() {
      this.$router.push({ name: "cadastro-saida" })
    },
    adicionarProdutos(id) {
      this.$router.push({ name: "adicionar-produto", params: { id: id } })
    },
    pesquisar() {
      if (this.search === "") {
        this.saidas = this.saidasSemfiltro
      } else {
        this.saidas = this.saidasSemfiltro
        var result = this.saidas.filter(obj => this.filtrarLista(obj))
        this.saidas = result
      }
    },
    filtrarLista(obj) {
      return (
        obj.vendedor
          .toString()
          .toUpperCase()
          .includes(this.search.toUpperCase(), 0) ||
        obj.id.toString().includes(this.search, 0) ||
        (obj.idRetorno !== null && obj.idRetorno.toString().includes(this.search, 0)) ||
        obj.praca
          .toString()
          .toUpperCase()
          .includes(this.search.toUpperCase(), 0) ||
        obj.dataSaidaString.toString().includes(this.search, 0) ||
        obj.dataPrevistaRetornoString.toString().includes(this.search, 0)
      )
    },
    alterarDatas(id) {
      this.$router.push({ name: "alterar-datas", params: { id: id } })
    },
    devolverItens(id) {
      this.abrirProgressCircular()
      servicoRetorno.devolverTodosItens(id).then(
        res => {
          if (res.status === 200) {
            this.fecharProgressCircular()
            this.buscarSaidas()
          }
        },
        err => {
          // eslint-disable-next-line
          console.log(err)
          this.fecharProgressCircular()
        }
      )
    },
    unificar(id) {
      this.$router.push({ name: "unificar-saidas", params: { id: id } })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
